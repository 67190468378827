<template>
  <div class="complaintdetail">
    <van-nav-bar title="投诉与建议" left-arrow @click-left="onClickLeft" />
    <div class="main" v-if="List && List.length > 0">
      <van-steps direction="vertical" :active="active">
        <van-step v-for="(item, index) in List" :key="index">
          <van-cell>
            <template #title>
              <div>
                {{ item.content }}
              </div>
              <div v-if="item.pic" class="label_img">
                <van-image
                  v-for="(val, key) in item.pic"
                  :key="key"
                  width="40"
                  height="40"
                  lazy-load
                  :src="val"
                  @click="yunlan(item.pic, key)"
                />
              </div>
              <div class="list_time">{{ item.createtime_str }}</div>
            </template>
          </van-cell>
          <template #active-icon>
            <van-image round width="26px" height="26px" :src="item.avatar" />
          </template>
          <template #inactive-icon>
            <van-image round width="26px" height="26px" :src="item.avatar" />
          </template>
        </van-step>
      </van-steps>
      <div class="liuyan">
        <van-button
          block
          round
          type="danger"
          @click="submit(id)"
          :disabled="status == 2 ? true : false"
        >
          {{ status == 2 ? "已完结" : "继续留言" }}
        </van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
export default {
  name: "ComplaintDetail",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      shareid: 0,
      merchid: 0,
      List: [],
      id: 0,
      active: 0,
      status: 0
    };
  },
  mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : 0;
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid;
    } else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "投诉与建议");

    this.init();
  },
  methods: {
    init() {
      console.log("ComplaintDetail");
      this.getdetails();
      this.setfeedback();
    },
    getdetails() {
      let _that = null;
      _that = this;
      if (_that.id > 0) {
        _that.$axios.defaults.headers["Content-Type"] =
          "application/x-www-form-urlencoded";
        _that.$axios.defaults.headers["Authorization"] = localStorage.getItem(
          "DK_UID"
        );
        _that.$axios
          .post(
            _that.$store.state.domain + "web/feedback/get_details",
            _that.$qs.stringify({
              id: _that.id
            })
          )
          .then(res => {
            console.log(res);
            _that.loading = false;
            if (res.data.code == 100000) {
              _that.List = res.data.data;
              _that.List.forEach(item => {
                if (item.id == _that.id) {
                  _that.status = item.status;
                }
              });
              _that.active = _that.List.length - 1;
            } else {
              _that.$toast(res.data.msg ? res.data.msg : "查询失败");
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    yunlan(image, index) {
      ImagePreview({
        images: image,
        startPosition: index
      });
    },
    // 继续留言
    submit(id) {
      this.$router.push({
        name: "Complaint",
        query: { id: id, shareid: this.shareid, merchid: this.merchid }
      });
    },
    setfeedback() {
      let _that = null;
      _that = this;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/feedback/set_reply",
          _that.$qs.stringify({
            id: _that.id
          })
        )
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        })
    }
  }
};
</script>
<style lang="less">
.complaintdetail {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .main {
    padding-bottom: 60px;
    overflow: hidden;
    .van-steps {
      text-align: left;
      .van-cell {
        line-height: 20px;
        padding: 0 0 0 10px;
        .van-cell__title {
          flex: 1;
        }
        .label_img {
          margin-top: 5px;
          .van-image {
            margin-right: 5px;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
        .list_time {
          margin-top: 5px;
          color: #999;
        }
      }
      .van-steps--vertical {
        padding-left: 46px;
      }
    }
    .liuyan {
      padding: 16px;
    }
  }
}
</style>